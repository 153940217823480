import { translate } from '@/domains/core/localization/translate';
export const {
  title,
  subTitle,
  text,
  button
} = translate({
  "title": "Erreur 404",
  "subTitle": "Ah. \xC7a, c'\xE9tait pas dans les plans. D\xE9sol\xE9.",
  "text": "Suivez le guide, on vous emm\xE8ne ailleurs.",
  "button": "Retourner \xE0 l'accueil"
});